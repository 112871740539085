// Info.jsx
import React from 'react';
import './Info.css';

const Info = ({ onClose }) => {
  return (
    <div className="info-overlay">
      <div className="info-modal">
        <button className="close-button" onClick={onClose}>×</button>
    <p>
    Ex-Situ is a research and archive project that maps digital collections of museums in the Global North, focusing on the migration of artifacts from diverse cultures in the Global South. All images and related content displayed on this website are hosted on third-party museum websites and are not stored on our servers. This project uses hyperlinks to reference these images, adhering to the terms and conditions set by the content owners. 
        The use of images and information is solely for educational and non-commercial purposes, in compliance with the Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International (CC BY-NC-SA 4.0) license. Proper attribution is provided for all referenced materials. 
        Users can visit to the original sources through the provided links for more information.
    </p>
    <p>
        <a href="https://www.are.na/h-burak-yel-9blqk4ivewg/ex-situ-a7gbi3jid_m" target="_blank" rel="noopener noreferrer">Are.na</a> | 
        <a href="https://github.com/hburakyel/ex-situ" target="_blank" rel="noopener noreferrer">GitHub</a> | 
        <a href="https://www.patreon.com/c/ex_situ" target="_blank" rel="noopener noreferrer">Patreon</a> | 
        <a href="mailto:burak@studio-abo.com">Contact</a>
    </p>
      </div>
    </div>
  );
};

export default Info;
