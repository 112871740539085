import React from 'react';
import './Modal.css';

const Modal = ({ isOpen, onClose, content, isInfoModal }) => {
  if (!isOpen || !content) return null;

  // If it's an info modal, render static content
  if (isInfoModal) {
    return (
      <div className="modal">
        <div className="modal-content">
          <span className="close" onClick={onClose}>
          <svg width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>          </span>
          <div className="info-tab">
            {content}  {/* Render the content passed from Info component */}
          </div>
        </div>
      </div>
    );
  }

  // Otherwise, render the object details modal
  const {
    title,
    imgUrl,
    timeInfo,
    institutionPlace,
    institutionName,
    placeName,
    inventoryNumber,
    linkText,
  } = content;

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          <i className="fa-solid fa-xmark"></i>
        </span>

        <div className="image-tab">
          <img src={imgUrl} alt={title} />
        </div>
        <div className="info-tab">
          <div className="cell">
            <span className="field-name">Title</span>
          </div>
          <div className="cell-content">
            <span>{title}</span>
          </div>

          <div className="cell">
            <span className="field-name">Time</span>
          </div>
          <div className="cell-content">
            <span>{timeInfo}</span>
          </div>

          <div className="cell">
            <span className="field-name">From</span>
          </div>
          <div className="cell-content">
            <span>{placeName}</span>
          </div>

          <div className="cell">
            <span className="field-name">To</span>
          </div>
          <div className="cell-content">
            <span>{institutionPlace}</span>
          </div>

          <div className="cell">
            <span className="field-name">Institution</span>
          </div>
          <div className="cell-content">
            <span>{institutionName}</span>
          </div>

          <div className="cell">
            <span className="field-name">Inventory Number</span>
          </div>
          <div className="cell-content">
            <span>{inventoryNumber}</span>
          </div>

          <div className="cell">
            <span className="field-name">Source Link</span>
          </div>
          <div className="cell-content">
            <span>
              <a href={linkText} target="_blank" rel="noopener noreferrer">
                {linkText}
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
